import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitleUpdater = () => {
  const location = useLocation();

  const metaDescription = document.querySelector('meta[name="description"]');

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "FORMIS | HEALTHCARE, SIMPLIFIED | INDIA";
        metaDescription.content =
          "From daily health essentials to life-enhancing products, FORMIS delivers everything you need in just 20 minutes. No delivery fees, no hidden charges, no minimum cart value—just fast, simple, reliable service.";
        break;

      case "/privacy-policy":
        document.title = "FORMIS | Privacy";
        metaDescription.content =
          "From daily health essentials to life-enhancing products, FORMIS delivers everything you need in just 20 minutes. No delivery fees, no hidden charges, no minimum cart value—just fast, simple, reliable service.";
        break;

      case "/shipping-and-refunds-policy":
        document.title = "FORMIS | Shipping and Refunds";
        metaDescription.content =
          "From daily health essentials to life-enhancing products, FORMIS delivers everything you need in just 20 minutes. No delivery fees, no hidden charges, no minimum cart value—just fast, simple, reliable service.";
        break;

      case "/terms":
        document.title = "FORMIS | Terms";
        metaDescription.content =
          "From daily health essentials to life-enhancing products, FORMIS delivers everything you need in just 20 minutes. No delivery fees, no hidden charges, no minimum cart value—just fast, simple, reliable service.";
        break;

      case "/connect":
        document.title = "FORMIS | Connect";
        metaDescription.content = "From daily health essentials to life-enhancing products, FORMIS delivers everything you need in just 20 minutes. No delivery fees, no hidden charges, no minimum cart value—just fast, simple, reliable service.";
        break;

      default:
        document.title = "FORMIS | HEALTHCARE, SIMPLIFIED | INDIA";
    }
  }, [location.pathname]);

  return null;
};

export default PageTitleUpdater;
