import { FaArrowRightLong } from "react-icons/fa6";
import "./footer.css";
import logo from "../../assets/formis-foressentials-logo.png";
// import Accordian from "./Accordian";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      id="footer"
      className="h-fit text-formisPeach mx-[40px] lg:mx-[60px] xl:mx-[90px] py-[80px]"
    >
      <section>
        <h2 className="text-[22px] lg:text-[34px] mb-[48px]">
          See what's possible with us.
        </h2>

        <div className="flex flex-col md:flex-row gap-8 lg:gap-10 lg:h-[322px]">
          <Link
            to="/connect"
            className="flex w-full lg:w-[50%] border border-formisPeach/30 hover:border-formisPeach transition cursor-pointer p-8"
          >
            <div>
              <h3 className="text-[18px] md:text-[24px] mb-[16px]">
                Contact us
              </h3>
              <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60 max-w-[80%] md:max-w-[90%] lg:max-w-[70%]">
                At FORMIS , your health isn’t just a priority—it’s personal.
                We’re not just delivering products; we’re delivering care,
                convenience, and a better way of living.
              </p>
            </div>
            <div className="self-end">
              <FaArrowRightLong />
            </div>
          </Link>

          <Link
            to="/about"
            className="flex w-full lg:w-[50%] border border-formisPeach/30 hover:border-formisPeach transition cursor-pointer p-8"
          >
            <div>
              <h3 className="text-[18px] md:text-[24px] mb-[16px]">About Us</h3>
              <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60 max-w-[80%] md:max-w-[90%] lg:max-w-[70%]">
                Transforming healthcare by integrating innovative technology
                with a human-centered approach, enabling proactive,
                personalized, and continuous care for all.
              </p>
            </div>
            <div className="self-end">
              <FaArrowRightLong />
            </div>
          </Link>
        </div>

        {/* <div className="mt-10 flex gap-5">
          <Link to="/about" className="hover:opacity-70 transition-opacity">
            About
          </Link>
          <Link to="/blog" className="hover:opacity-70 transition-opacity">
            Blog
          </Link>
          <Link to="/pricing" className="hover:opacity-70 transition-opacity">
            Pricing
          </Link>
        </div> */}
      </section>

      {/* Only on larger screen sizes */}
      {/* <section className="hidden md:grid grid-cols-5 gap-[32px] mt-[80px]">
        <div className="footer-item flex flex-col gap-[16px]">
          <span className="text-[#545454] text-[12px]">PLATFORM</span>
          <p>Overview</p>
          <p>Real-time data infrastructure</p>
          <p>Customer 360°</p>
          <p>Segmentation</p>
          <p>Analytics</p>
        </div>

        <div className="footer-item flex flex-col gap-[16px]">
          <span className="text-[#545454] text-[12px]">USE CASES</span>
          <p>Create a customer 360°</p>
          <p>Improve understanding of customers</p>
          <p>Lower customer acquisition cost</p>
          <p>Boost customer lifetime value</p>
          <p>Drive operational efficiencies</p>
        </div>

        <div className="footer-item flex flex-col gap-[16px]">
          <span className="text-[#545454] text-[12px]">INDUSTRIES</span>
          <p>Media & entertainment</p>
          <p>QSR</p>
          <p>Retail</p>
          <p>Travel & hospitality</p>
          <p>Financial services</p>
        </div>

        <div>
          <div className="footer-item flex flex-col gap-[16px] mb-[24px]">
            <span className="text-[#545454] text-[12px]">RESOURCES</span>
            <p>Blog</p>
            <p>Resources</p>
            <p>Developer Docs</p>
          </div>
          <div className="footer-item flex flex-col gap-[16px]">
            <span className="text-[#545454] text-[12px]">GET STARTED</span>
            <p>Contact Sales</p>
          </div>
        </div>

        <div className="footer-item flex flex-col gap-[16px]">
          <span className="text-[#545454] text-[12px]">COMPANY</span>
          <p>About</p>
          <p>Careers</p>
          <p>Partners</p>
          <p>Contact Us</p>
          <p>Security</p>
        </div>
      </section> */}

      {/* Mobile View */}
      {/* <section className="md:hidden mt-8 flex flex-col">
        <Accordian title="Platform">
          <p>Overview</p>
          <p>Real-time data infrastructure</p>
          <p>Customer 360°</p>
          <p>Segmentation</p>
          <p>Analytics</p>
        </Accordian>
        <Accordian title="Platform">
          <p>Overview</p>
          <p>Real-time data infrastructure</p>
          <p>Customer 360°</p>
          <p>Segmentation</p>
          <p>Analytics</p>
        </Accordian>
        <Accordian title="Platform">
          <p>Overview</p>
          <p>Real-time data infrastructure</p>
          <p>Customer 360°</p>
          <p>Segmentation</p>
          <p>Analytics</p>
        </Accordian>
      </section> */}

      {/* <section className="mt-[48px] lg:mt-28"> */}
      <section className="mt-[48px] lg:mt-10">
        <div className="flex flex-wrap gap-5 mb-[40px]">
          <p className="">Social Handles</p>
          <a
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-70 transition-opacity"
            href="https://www.facebook.com/heyformis/"
          >
            Facebook
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-70 transition-opacity"
            href="https://www.linkedin.com/company/heyformis/"
          >
            LinkedIn
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-70 transition-opacity"
            href="https://x.com/formispulse"
          >
            X
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-70 transition-opacity"
            href="https://www.instagram.com/heyformis/"
          >
            Instagram
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-70 transition-opacity"
            href="https://www.youtube.com/@heyformis/"
          >
            Youtube
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-70 transition-opacity"
            href="https://www.threads.net/@heyformis"
          >
            Threads
          </a>
          <Link to="/blog" className="hover:opacity-70 transition-opacity font-bold">
            Formis Pulse
          </Link>
        </div>

        <img src={logo} alt="FORMIS Logo" className="max-w-[290px] mb-[40px]" />

        <div className="grid grid-cols-2 md:flex flex-wrap gap-[20px] text-[14px] text-formisPeach/40">
          <p>© 2024 Formis® Health Technologies Pvt. Ltd.</p>
          <p className="cursor-pointer hover:text-formisPeach transition-colors duration-300">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
          <p className="cursor-pointer hover:text-formisPeach transition-colors duration-300">
            <Link to="/terms">Terms of Usage</Link>
          </p>
          <p className="cursor-pointer hover:text-formisPeach transition-colors duration-300">
            <Link to="/shipping-and-refunds-policy">
              Shipping and Refunds Policy
            </Link>
          </p>
          <p>Do Not Sell or Share My Personal Data</p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
