import { Link } from "react-router-dom";
import img2 from "../../assets/about/img2.jpeg";
import office from "../../assets/about/office.jpeg";
import contact from "../../assets/about/contact.png";

const JoinContact = () => {
  return (
    <section className="px-[40px] lg:px-[60px] xl:px-[90px] py-[90px] md:py-[75px] xl:py-[140px] bg-formisBlack15 text-formisPeach">
      <img src={contact} alt="img" className="xl:w-[90vw] mb-12" />
      <div>
        <h6 className="text-[12px] font-medium tracking-widest mb-6 md:mb-12">
          COMPANY
        </h6>
        <p
          className="text-[18px] md:text-[24px] xl:text-[30px] mb-6 md:mb-12
                        leading-[2rem] md:leading-[2.5rem] xl:leading-[3rem]"
        >
          In essence, Formis Health Technologies operates like an eternal,
          self-sustaining loop, spinning tirelessly to ensure that the continuum
          of care is seamless, integrated, and ever-present in the consumers
          life and stakeholders systems.
        </p>
        <Link to="/connect">
          <button
            className="border border-formisPeach text-formisBlack15 bg-formisPeach rounded-full font-medium px-6 py-3
                           hover:bg-white hover:text-formisBlack15 transition-colors"
          >
            Contact Us
          </button>
        </Link>
      </div>
    </section>
  );
};

export default JoinContact;
