import { useState } from "react";
import { FiPlus } from "react-icons/fi";

const Accordian = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full py-[13px]"
      >
        <span className="text-[18px]">{title}</span>

        <div className="text-xl border border-formisBlack15 p-1 rounded-full font-light">
          <FiPlus className={`${isOpen ? "rotate-45" : ""} transition-all duration-500`} />
        </div>
      </button>

      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="mb-[8px] font-light text-[14px] overflow-hidden">
          <div className="flex flex-col gap-[16px] last:mb-[14px]">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordian;
