import { FaLinkedin } from "react-icons/fa";
import team1 from "../../assets/about/team1.png";
import avi from "../../assets/about/avinandan.png";
import aayesha from "../../assets/about/aayesha.png";
import nikhil from "../../assets/about/nikhil.png";
import sarthak from "../../assets/about/sarthak.png";

const Team = () => {
  return (
    <section className="py-[60px] lg:py-[100px] bg-formisBlack15 text-formisPeach px-[40px] lg:px-[60px] xl:px-[90px]">
      <h5 className="text-[22px] md:text-[24px] lg:text-[34px] mb-5 md2:mb-16">
        Leadership Team
      </h5>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 2xl:gap-14">
        <div>
          <img src={aayesha} />
          <div className="flex mt-4 justify-between">
            <div>
              <p>Aayesha</p>
              <p className="text-[13px] opacity-60">Co-Founder and CFO</p>
            </div>
          </div>
        </div>
        <div>
          <img src={avi} />
          <div className="flex mt-4 justify-between">
            <div>
              <p>Avinandan Chaudhary</p>
              <p className="text-[13px] opacity-60">Co-Founder and CEO</p>
            </div>
            <a href="https://www.linkedin.com/in/avinandanchoudhary/" target="_blank">
              <FaLinkedin className="text-xl" />
            </a>
          </div>
        </div>
        <div>
          <img src={nikhil} />
          <div className="flex mt-4 justify-between">
            <div>
              <p>Nikhil Singh Charak</p>
              <p className="text-[13px] opacity-60">Founding Member and COO</p>
            </div>
            <a href="https://www.linkedin.com/in/nikhil-singh-charak-896936150/" target="_blank">
              <FaLinkedin className="text-xl" />
            </a>
          </div>
        </div>
        <div>
          <img src={sarthak} />
          <div className="flex mt-4 justify-between">
            <div>
              <p>Sarthak Tyagi</p>
              <p className="text-[13px] opacity-60">Founding Member and CTO</p>
            </div>
            <a href="https://www.linkedin.com/in/sarthak-tyagi/" target="_blank">
              <FaLinkedin className="text-xl" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
