import { useEffect, useState } from "react";
import Navbar from "../components/Main/Navbar/Navbar";
import Hero from "../components/Main/Hero/Hero";
import Features from "../components/Main/Features/Features";
import Highlights from "../components/Main/Highlights/Highlights";
// import Redefine from "../components/Main/Redefine/Redefine";
import Footer from "../components/Footer/Footer";
import LoaderAnimation from "../components/LoaderAnimation";
import FAQ from "../components/Main/FAQ/FAQ";
import Carousel from "../components/Main/Carousel/Carousel";

// import gif from "../assets/modal-gif.gif";

const Main = ({ dropdown, setDropdown }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNavbarLoaded, setIsNavbarLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const headerTimer = setTimeout(() => {
        setIsNavbarLoaded(true);
      }, 200);
      return () => clearTimeout(headerTimer);
    }
  }, [isLoaded]);

  return (
    <div>
      <LoaderAnimation isLoaded={isLoaded} />
      <Navbar
        dropdown={dropdown}
        setDropdown={setDropdown}
        isLoaded={isLoaded}
      />
      <div
        className={`relative overflow-hidden ${
          dropdown
            ? "opacity-10 transition-opacity duration-300"
            : "opacity-100"
        }`}
      >
        {isLoaded && (
          <div>
            <Hero isLoaded={isNavbarLoaded} />
            <Features />
            <Carousel />
            <Highlights />
            {/* <Redefine /> */}
            <FAQ />
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
