import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import img1 from "../../../assets/carousel/1.png";
import img2 from "../../../assets/carousel/2.png";
import img3 from "../../../assets/carousel/3.png";
import img4 from "../../../assets/carousel/4.png";
import img5 from "../../../assets/carousel/5.png";

const Carousel = () => {

  return (
    <div className="bg-formisBlack15 text-formisPeach px-[40px] xl:px-[90px] py-20">
      <section className="fade-in text-4xl md:text-6xl lg:text-[5.5rem] font-medium mb-[80px] lg:mb-[140px]">
        <h1 className="leading-[2.5rem] md:leading-[4.5rem] lg:leading-[7rem]">
          The FORMIS Difference
          <span className="text-formisOrange">.</span> <br />
        </h1>
      </section>
      <Swiper
        spaceBetween={30}
        slidesPerView={3.75}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: ".next-btn",
          prevEl: ".prev-btn",
        }}
        modules={[Navigation]}
        breakpoints={{
          200: {
            slidesPerView: 1.5,
            spaceBetween: 30,
          },
          600: {
            slidesPerView: 2.5,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
        }}
      >
        <SwiperSlide>
          <div className="text-formisPeach">
            <div className="">
              <img
                src={img1}
                alt="Keep It Simple"
                className="w-full aspect-auto mb-[40px]"
              />
            </div>

            <div className="h-[320px] md:h-[370px] md2:h-[250px] 3xl:h-[300px] flex flex-col">
              <div className="">
                <h2 className="text-[16px] lg:text-[20px] mb-[20px] font-[600]">
                  Never on MRP, No Hassle
                </h2>
                <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] text-formisPeach opacity-60">
                  We believe in giving you the best
                  <span className="font-bold">—always below MRP</span>, with no
                  hidden charges or hassles. FORMIS is like a family member,
                  always there for you on{" "}
                  <span className="font-bold">WhatsApp</span>, ready to help
                  with your health and wellness needs, without breaking the
                  bank.{" "}
                  <span className="font-bold">
                    Just say “Hi, FORMIS” and we’ll be there to serve you.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="text-formisPeach">
            <div className="">
              <img
                src={img2}
                alt="Instant Access"
                className="w-full aspect-auto  mb-[40px]"
              />
            </div>

            <div className="h-[270px] md:h-[300px] md7:h-[250px] 3xl:h-[230px] flex flex-col justify-between">
              <div className="">
                <h2 className="text-[16px] lg:text-[20px] mb-[20px] font-[600]">
                  Automated Monthly Refills
                </h2>
                <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] text-formisPeach opacity-60">
                  Tired of running out of your daily essentials? With{" "}
                  <span className="font-bold">automated monthly refills</span>,{" "}
                  FORMIS ensures you never miss a dose or run low on your
                  must-haves. We take care of your refills, so you don’t have to
                  worry.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="text-formisPeach">
            <div className="">
              <img
                src={img3}
                alt="Timelines"
                className="w-full aspect-auto mb-[40px]"
              />
            </div>

            <div className="h-[270px] md:h-[300px] md7:h-[250px] 3xl:h-[230px] flex flex-col justify-between">
              <div className="">
                <h2 className="text-[16px] lg:text-[20px] mb-[20px] font-[600]">
                  Lab Tests & Diagnostics – Coming Soon
                </h2>
                <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] text-formisPeach opacity-60">
                  Healthcare is evolving, and so are we.{" "}
                  <span className="font-bold">Lab tests and diagnostics</span>{" "}
                  are coming soon to FORMIS , making it even easier to manage
                  your health from the comfort of your home. Get ready for
                  seamless health testing.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="text-formisPeach">
            <div className="">
              <img
                src={img4}
                alt="Stellar Support"
                className="w-full aspect-auto mb-[40px]"
              />
            </div>

            <div className="h-[270px] md:h-[300px] md7:h-[250px] 3xl:h-[230px] flex flex-col justify-between">
              <div className="">
                <h2 className="text-[16px] lg:text-[20px] mb-[20px] font-[600]">
                  Exclusive Access to FORMIS forLIFE
                </h2>
                <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] text-formisPeach opacity-60">
                  As a FORMIS member, you get{" "}
                  <span className="font-bold">free, exclusive access</span> to{" "}
                  FORMIS forLIFE —a world of life-enhancing events, festivals,
                  and re-energizing experiences designed to keep you youthful,
                  fit, and stress-free. It’s more than health, it’s a lifestyle.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="text-formisPeach">
            <div className="">
              <img
                src={img5}
                alt="Always On"
                className="w-full aspect-auto mb-[40px]"
              />
            </div>

            <div className="h-[270px] md:h-[300px] md7:h-[250px] 3xl:h-[230px] flex flex-col justify-between">
              <div className="">
                <h2 className="text-[16px] lg:text-[20px] mb-[20px] font-[600]">
                  Insights, Reminders & Urgent Care Spots Locator
                </h2>
                <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] text-formisPeach opacity-60">
                  FORMIS is more than just deliveries. Get{" "}
                  <span className="font-bold">
                    personalized insights, reminders, and alerts
                  </span>{" "}
                  to keep you on track with your health goals. Plus, find urgent
                  care spots near you when you need them most
                  <span className="font-bold">—all through WhatsApp</span>.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <section className="flex  md:flex-row gap-2 md:gap-6 mt-10 justify-end">
        <div className="flex justify-end gap-4">
          <a
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
            className="bg-transparent h-[47px] px-2 md:px-4 rounded-full text-formisPeach cursor-pointer border border-formisPeach
                      flex items-center justify-center text-[10px] md:text-[14px] font-[500]
                      hover:bg-formisPeach hover:border-formisPeach hover:text-formisBlack15 transition-colors"
          >
            Order Now On WhatsApp
          </a>
        </div>

        <div className="flex gap-2 md:gap-4 justify-end">
          <button className="bg-formisPeach text-formisBlack15 prev-btn font-light text-xs h-[47px] w-[47px] flex items-center justify-center rounded-full">
            <FaArrowLeft />
          </button>
          <button className="bg-formisPeach text-formisBlack15 next-btn font-light text-xs h-[47px] w-[47px] flex items-center justify-center rounded-full">
            <FaArrowRight />
          </button>
        </div>
      </section>
    </div>
  );
};

export default Carousel;
