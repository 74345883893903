import { useEffect, useState } from "react";
import {  IoIosCall } from "react-icons/io";
import peachlogo from "../../../assets/formis-foressentials-logo.png"

import { Link } from "react-router-dom";

const Navbar = ({ isLoaded, background }) => {
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const [isAtTop, setIsAtTop] = useState(true);

  const controlNavbar = () => {
    if (window.scrollY === 0) {
      setIsAtTop(true);
      setShow(false);
    } else {
      setIsAtTop(false);

      if (window.scrollY > lastScrollY) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <main>
      <nav
        id="navbar"
        style={{ backgroundImage: `url(${background})` }}
        className={`text-[12px] 2xl:text-[13px] 3xl:text-[15px] fixed z-[100] px-[20px] md:px-[40px] lg:px-[60px] 2xl:px-[90px] active ${
          show ? "hidden1" : ""
        } w-full h-[80px] flex items-center justify-between transition-colors duration-500 ease-in-out ${
          isAtTop ? "bg-transparent text-formisPeach" : "bg-formisBlack15"
        } 
        ${isLoaded ? "translate-y-0" : "-translate-y-full"}`}
      >
        <section className="flex items-center gap-4 2xl:gap-8 3xl:gap-10">
          <Link to="/">
            <img
              src={peachlogo}
              alt="Formis Logo"
              className="transition-transform ease-in-out object-fit w-[200px] md:w-[220px] lg:w-[250px] xl:w-[290px]"
            />
          </Link>
        </section>

        <section className="flex gap-[18px] items-center">
          <div className="flex items-center gap-[12px] text-[14px] font-[500]">
            <a
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
              className={`hidden xl:flex items-center justify-center cursor-pointer ${
                isAtTop ? "button-white" : "button-white"
              } h-[40px] px-4`}
            >
              Order Now On WhatsApp
            </a>
            <Link
              to="/about"
              className={`xl:flex items-center cursor-pointer ${
                isAtTop ? "button-white" : "button-white"
              } h-[40px] px-2 md:px-4 text-[12px] md:text-[14px] hidden`}
            >
              Know More About FORMIS
            </Link>

            <Link
              to="/connect"
              className={`${
                isAtTop ? "button-white" : "button-white"
              } size-[40px] text-lg flex items-center justify-center`}
            >
              <IoIosCall />
            </Link>
          </div>
        </section>
      </nav>

    </main>
  );
};

export default Navbar;
