import { useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";

const Dropdown = ({ title, children, color }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="border-t border-formisPeach/30 text-formisPeach font-medium flex justify-between items-center w-full pt-6 py-[13px]"
      >
        <span className="text-[18px] md:text-[20px] lg:text-[24px] text-left">
          {title}
        </span>

        {isOpen ? (
          <div className="text-xl font-light transition-all duration-500">
            <FiMinus />
          </div>
        ) : (
          <div className="text-xl font-light transition-all duration-500">
            <FiPlus />
          </div>
        )}
      </button>

      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-formisPeach ${
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="mb-6 text-formisPeach overflow-hidden">
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
