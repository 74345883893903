import AwardsMarquee from "../../AwardsMarquee";
// import hero from "../../../assets/image.png";
import heromain from "../../../assets/bg-hero-main.png";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const Hero = ({ isLoaded }) => {
  return (
    <header
      className={`transition-opacity duration-1000 ${
        isLoaded ? "opacity-100" : "opacity-0"
      }`}
      id="hero"
    >
      <section
        className="bg-cover bg-no-repeat h-[100svh] relative z-40 px-[40px]"
        style={{ backgroundImage: `url(${heromain})` }}
      >
        <div className="absolute top-0 left-0 h-full w-full bg-formisBlack15/50"></div>
        <section
          className="relative z-10 h-[80%] lg:h-[75%] pt-[80px] lg:pt-[90px] 3xl:pt-[150px]
                            mobile-landscape:h-[70%]"
        >
          <div className="flex flex-col justify-center">
            <div
              className="mt-20 lg:mt-[20px] header-text font-[500]
                            max-w-[920px] mx-auto text-formisPeach text-center
                            mobile-landscape:text-[20px] mobile-landscape:mt-0"
            >
              <h1 className="leading-[3rem] md:leading-[3.5rem] xl:leading-[5rem] mobile-landscape:leading-[30px]">
                Get Your Health Essentials {" "}
                <br className="hidden lg:block mobile-landscape:block" />
                Delivered in Minutes!
              </h1>
            </div>

            <div
              className="font-light text-formisPeach max-w-[850px] text-center mx-auto 
                            mt-5 mobile-landscape:mt-2"
            >
              <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[30px] opacity-60 font-[500] tracking-wide">
                From daily health essentials to life-enhancing products,{" "}
                <span className="font-bold">FORMIS® forESSENTIALS</span>{" "}
                delivers it all in just{" "}
                <span className="font-bold">15 minutes</span>.{" "}
                <span className="font-bold">Zero</span> delivery fees,{" "}
                <span className="font-bold">Zero</span> hidden charges, <span className="font-bold">Zero</span> minimum
                cart value—just fast, simple, reliable service at your fingertips.
                Because when it comes to your health, you’ve got FORMIS.
              </p>
            </div>

            <div className="flex flex-col md:flex-row gap-2 md:gap-5 items-center justify-center font-[500] mt-10 mobile-landscape:mt-5">
              <a
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
                className="text-formisBlack15 text-[12px] md:text-[14px] border border-formisPeach bg-formisPeach h-[47px] rounded-full px-4 
                          hover:bg-transparent hover:text-formisPeach transition-colors cursor-pointer flex items-center justify-center
                          mobile-landscape:h-[38px]"
              >
                Order Now On WhatsApp
              </a>
              <Link to="/connect" className="scroll-smooth">
                <button
                  className="hidden lg:block text-formisPeach text-[14px] border border-formisPeach h-[47px] rounded-full px-6 
                                hover:bg-formisPeach hover:text-formisBlack15 transition"
                >
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </section>

        <div
          className="mt-24 h-[20%] lg:h-[25%] px-[20px] md:mx-[40px] lg:mx-[60px] 2xl:mx-[90px]
                        mobile-landscape:h-[30%]"
        >
          <AwardsMarquee />
        </div>
      </section>
      {/* 
      <section
        style={{ backgroundImage: `url(${heromain})` }}
        className="bg-cover bg-no-repeat"
      >
        <img src={hero} className="mx-auto max-w-[25vw] pt-10" />
      </section> */}
    </header>
  );
};

export default Hero;
